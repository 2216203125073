<template lang="pug">
section.main
  //- aside.side-menu(v-if="$auth.loggedIn")
  aside.side-menu
    Sidemenu
  main.core
    //- active-team
    Tabmenu(:tabs="items")
    slot
</template>

<script setup>
const route = useRoute();

const items = ref([
  {
    label: "Content",
    link: `/websites/${route.params.website}/content`,
  },
  {
    label: "Components",
    link: `/websites/${route.params.website}/components`,
  },
  {
    label: "Themes",
    link: `/websites/${route.params.website}/themes`,
  },
  // {
  //   label: 'Extensions',
  //   link: `/websites/${this.$route.params.website}/extensions`,
  // },
  {
    label: "Redirects",
    link: `/websites/${route.params.website}/redirects`,
  },
  {
    label: "Settings",
    link: `/websites/${route.params.website}/settings`,
  },
]);

// import SideMenu from "~/components/Sidemenu";
// import TabMenu from "~/components/Tabmenu";
// import ActiveTeam from "~/components/ActiveTeam";

// export default {
//   components: {
//     SideMenu,
//     TabMenu,
//     ActiveTeam,
//   },
//   data() {
//     return {
//       items: [
//         {
//           label: "Content",
//           link: `/websites/${this.$route.params.website}/content`,
//         },
//         {
//           label: "Components",
//           link: `/websites/${this.$route.params.website}/components`,
//         },
//         // {
//         //   label: 'Extensions',
//         //   link: `/websites/${this.$route.params.website}/extensions`,
//         // },
//         {
//           label: "Redirects",
//           link: `/websites/${this.$route.params.website}/redirects`,
//         },
//         {
//           label: "Settings",
//           link: `/websites/${this.$route.params.website}/settings`,
//         },
//       ],
//       activeItem: 1,
//     };
//   },
// };
</script>
<style lang="scss">
section.main {
  overflow-x: hidden;
  display: flex;
  width: 100%;
  .side-menu {
    // flex: 1;
    width: rempx(91);
  }
  .core {
    flex: 1;
    // overflow: hidden;
  }
}
</style>
